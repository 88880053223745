<template>
  <b-row>
    <b-row>
      <b-col cols="12">
        <category-table-basic></category-table-basic>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
import {BCol, BRow} from 'bootstrap-vue'
import CategoryTableBasic from "../../../table/vue-good-table/CategoryTableBasic";

export default {
  name: "CategoryPreview",
  components: {CategoryTableBasic, BRow, BCol}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>